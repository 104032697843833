<template>
  <b-row>
    <b-col>
      <b-card>
        <template #header>
          <strong>修改密碼</strong>
        </template>
        <b-row>
          <b-col md="6">
            <b-form-group label="新密碼">
              <b-form-input type="password" v-model="password" autocomplete="new-password"></b-form-input>
            </b-form-group>
            <b-form-group label="確認新密碼">
              <b-form-input type="password" v-model="repassword" autocomplete="new-password"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <template #footer>
          <b-button variant="primary" class="float-right" @click="update()"> 確認修改 </b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SystemSetting",
  data: () => ({
    username: "",
    password: "",
    repassword: "",
  }),
  mounted() {
    this.getAccount();
  },
  methods: {
    getAccount() {
      this.$http.get("/account").then((response) => {
        if (response.data.success) {
          this.username = response.data.result.username;
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
    update() {
      if (this.password === "") {
        this.$notify({ type: "error", text: "密碼不得為空" });
        return;
      }

      if (this.password !== this.repassword) {
        this.$notify({ type: "error", text: "請輸入相同確認新密碼" });
        return;
      }

      this.$http.patch("/account/password", { username: this.username, password: this.password }).then((response) => {
        if (response.data.success) {
          this.$notify({ type: "success", text: "更新成功" });
          this.password = "";
          this.repassword = "";
        } else {
          this.$notify({ type: "error", text: response.data.message });
        }
      });
    },
  },
};
</script>